import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import PersonIcon from '@material-ui/icons/Person';
import ExploreIcon from '@material-ui/icons/Explore';
import RateReviewIcon from '@material-ui/icons/RateReview';
import Divider from '@material-ui/core/Divider';
import Float from '../../../Services/Float';
import DisplayScoreSlider from './DisplayScoreSlider/DisplayScoreSlider';
import { WarningRounded } from '@material-ui/icons';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
import Button from '@material-ui/core/Button';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import { APIResource } from '../../../Services/APIResource/APIResource';
import { ReviewQuickSelect } from '../../Review/ReviewQuickSelect';

import {
    isNTXRiskAssessmentType,
    isGROUPRiskAssessmentType,
    isBPCERiskAssessmentType,
    MRA_PROCESS,
    MRA_STATUS,
} from '../../../Services/MRA';
import { getIdFromIri } from '../../../Services/utils';
import { ParameterSelect } from '../../Forms/ParameterSelect/ParameterSelect';
import {
    PARAMETER_TYPE_MODEL_RISK_ASSESSMENT_RESULT,
    PARAMETER_TYPE_MODEL_USE_VALIDATION_STATUS,
    PARAMETER_TYPE_MRA_STATUS
} from '../../../Admin/ParameterAdmin';
import User from '../../../Services/User/User';
import { getParamByIri, userIsLod2, userIsLod1, userHasRoleMRM } from '../../../Store/ParameterStore';
import { useTranslation } from 'react-i18next';
import { openModal } from '../../Modal/OpenModal';
import { CONTEXT_DETAIL } from '../../../Services/APIResource/APIResource';
import Tooltip from '@material-ui/core/Tooltip';
import { ChartRadarDimensions } from './ChartRadarDimensions';
import APIResourceStore from '../../../Store/APIResourceStore';
import { DisplayTextField } from '../../Display/DisplayTextField/DisplayTextField';

const PanelStatusItem = (props) => {
    const { loadingStatusSave, mraStatus, onStatusChange } = props;

    return (
        <div style={{ ...styles.versionAuthor, fontWeight: 'bold' }}>
            {loadingStatusSave && <i className="fa fa-circle-notch text-primary fa-rotate fa-spin"></i>}

            {!loadingStatusSave && (
                <ParameterSelect
                    label=""
                    field={{
                        params: {
                            type: PARAMETER_TYPE_MRA_STATUS,
                            filters: [
                                (element) => {
                                    const allowed = [
                                        MRA_STATUS.ON_GOING,
                                        MRA_STATUS.VALIDATED,
                                        MRA_STATUS.FINALIZED,
                                    ].includes(element.systemId);
                                    return allowed;
                                },
                            ],
                        },
                    }}
                    value={mraStatus ? mraStatus['@id'] : undefined}
                    disabled={!userHasRoleMRM()}
                    onChange={function (status) {
                        onStatusChange(status);
                    }}
                    optionsSort={(a, b) => {
                        if (a.id > b.id) return 1;
                        return -1;
                    }}
                    required={false}
                    clearable={false}
                />
            )}
        </div>
    );
};
PanelStatusItem.propTypes = {
    loadingStatusSave: PropTypes.bool,
    mraStatus: PropTypes.object,
    onStatusChange: PropTypes.func,
};

const PanelMraModelsItem = (props) => {
    const { mras, canDetach, detachLoading, onDetach } = props;

    const { t } = useTranslation();

    return (
        <div style={{ ...styles.versionAuthor, marginBottom: '15px' }}>
            {mras.map((m) => (
                <div key={`overview_detach_${m.id}`} style={styles.mragroup}>
                    <Tooltip title={m.name} placement="top-start" arrow>
                        <div
                            style={{
                                ...styles.functionalid,
                                ...(!canDetach && { width: '100%' }),
                                ...{
                                    overflowWrap: 'anywhere',
                                    cursor: 'pointer',
                                    color: '#59237f',
                                },
                            }}
                            onClick={() => {
                                const id = m.model.id || getIdFromIri(m.model);
                                openModal({
                                    props: {
                                        instanceId: 'models',
                                        id: id,
                                        context: CONTEXT_DETAIL,
                                        modalTitle: m.functionalID + ' (' + m.initialID + ')',
                                        allowStayInModal: true,
                                        parentInstanceId: null,
                                        parentId: null,
                                        postSaveRedirectUrl: `/resource/models/${id}/detail`,
                                        parentResourceComponent: null,
                                    },
                                });
                            }}
                        >
                            {m.functionalID + (m.initialID ? ' (' + m.initialID + ')' : '')}
                        </div>
                    </Tooltip>
                    {canDetach && (
                        <Button
                            color="primary"
                            variant="outlined"
                            size="small"
                            disabled={detachLoading}
                            onClick={() => onDetach(m)}
                        >
                            {detachLoading ? <LoadingIndicator /> : t('detach')}
                        </Button>
                    )}
                </div>
            ))}
        </div>
    );
};
PanelMraModelsItem.propTypes = {
    mras: PropTypes.array,
    canDetach: PropTypes.bool,
    detachLoading: PropTypes.bool,
    onDetach: PropTypes.func,
};

const PanelReviewItem = (props) => {
    const { mras, review, loadingReviewSave, onReviewChange, onOpenValidation } = props;

    return (
        <div style={{ ...styles.versionAuthor, marginBottom: '15px' }}>
            {loadingReviewSave && <LoadingIndicator />}

            {!loadingReviewSave && (
                <div key={`overview_review`} style={styles.mragroup}>
                    <a href={`/resource/reviews/${review.id}/detail`} rel="noreferrer" target="_blank">
                        <div style={{ ...styles.functionalid }}>
                            <div>
                                {review.reviewId} {review.title}
                            </div>
                        </div>
                    </a>
                    {userHasRoleMRM() && (
                        <ReviewQuickSelect
                            resource={APIResourceStore.resources.reviews}
                            values={[review['@id']]}
                            additionalProperties={['models']}
                            filters={(items) => {
                                return items.filter((review_item) => {
                                    const { models } = review_item;
                                    if (models) {
                                        const modelsIds = mras.map(
                                            (mr) => mr.model.id.toString() || getIdFromIri(mr.model)
                                        );
                                        return modelsIds.every((m) => {
                                            const reviewModelsIds = models.map((rmo) => getIdFromIri(rmo));
                                            return reviewModelsIds.includes(m);
                                        });
                                    }
                                    return false;
                                });
                            }}
                            multi={true}
                            buttonLabel="Change"
                            buttonProps={{
                                variant: 'outlined',
                                size: 'small',
                            }}
                            onChange={onReviewChange}
                            inModal={false}
                            openValidation={onOpenValidation}
                            clearable={true}
                        />
                    )}
                </div>
            )}
        </div>
    );
};
PanelReviewItem.propTypes = {
    mras: PropTypes.array,
    review: PropTypes.object,
    loadingReviewSave: PropTypes.bool,
    onReviewChange: PropTypes.func,
    onOpenValidation: PropTypes.func,
};

const PanelScoreSlidersItem = (props) => {
    const { averageScore, keyDimensionsMaxScore } = props;

    return (
        <>
            <div style={styles.scoreMark}>
                <DisplayScoreSlider
                    label="Average"
                    highlight={averageScore > keyDimensionsMaxScore}
                    score={
                        averageScore && !isNaN(averageScore) && averageScore >= 0
                            ? Float.format(averageScore, 2)
                            : 'N/A'
                    }
                />
            </div>
            <div style={styles.scoreMark}>
                <DisplayScoreSlider
                    label="Key Dimensions Max"
                    highlight={averageScore < keyDimensionsMaxScore}
                    score={
                        keyDimensionsMaxScore && !isNaN(keyDimensionsMaxScore) && keyDimensionsMaxScore >= 0
                            ? Float.format(keyDimensionsMaxScore, 2)
                            : 'N/A'
                    }
                />
            </div>
        </>
    );
};
PanelScoreSlidersItem.propTypes = {
    averageScore: PropTypes.number,
    keyDimensionsMaxScore: PropTypes.number,
};

const PanelMraResultItem = (props) => {
    const { mra, isNTXDimension, isGroupDimension } = props;

    const [validationStatus, setValidationStatus] = useState(null);
    const [validationStatusRationale, setValidationStatusRationale] = useState(null);

    const riskResult = getParamByIri(mra.modelRiskAssessmentResult);

    const getData = async () => {
        const model = await APIResourceStore.resources.models.getItemFromResourcePath(mra.model);
        setValidationStatus(getParamByIri(model.validationStatus));
        setValidationStatusRationale(model.validationStatusRationale);
    }
    getData();

    return (
        <>
            <div style={{ ...styles.versionAuthor, fontWeight: 'bold' }}>
                <ParameterSelect
                    label=""
                    field={{
                        params: {
                            type: PARAMETER_TYPE_MODEL_RISK_ASSESSMENT_RESULT,
                            filters: [
                                (element) => {
                                    if (isNTXDimension) {
                                        return isNTXRiskAssessmentType(element.systemId);
                                    } else if (isGroupDimension) {
                                        return isGROUPRiskAssessmentType(element.systemId);
                                    } else {
                                        return isBPCERiskAssessmentType(element.systemId);
                                    }
                                },
                            ],
                        },
                    }}
                    optionsSort={(a, b) => {
                        if (a.id > b.id) return 1;
                        return -1;
                    }}
                    value={riskResult ? riskResult['@id'] : undefined}
                    disabled
                    required={false}
                />
            </div>
            <div style={{ ...styles.versionAuthor, fontWeight: 'bold' }}>
                <Typography component="span" variant='body1' color='textPrimary' style={{marginLeft: "-20px"}}>
                    Validation status
                </Typography>
                <ParameterSelect
                    label=""
                    field={{
                        params: {
                            type: PARAMETER_TYPE_MODEL_USE_VALIDATION_STATUS,
                        },
                    }}
                    value={validationStatus ? validationStatus['@id'] : undefined}
                    disabled
                    required={false}
                />
            </div>
            <div style={{ ...styles.versionAuthor }}>
                <Typography component="span" variant='body1' color='textPrimary' style={{marginLeft: "-20px"}}>
                    Validation status rationale
                </Typography>
                <DisplayTextField
                    value={validationStatusRationale}
                    disabled
                />
            </div>
        </>
    );
};
PanelMraResultItem.propTypes = {
    mra: PropTypes.object,
    isNTXDimension: PropTypes.bool,
    isGroupDimension: PropTypes.bool,
};

export const OverviewPanelHorizontal = (props) => {
    const [author, setAuthor] = useState('Generic user');
    const [subdimensions, setSubdimensions] = useState([]);
    const [criticalDimensions, setCriticalDimensions] = useState([]);

    const radarRef = useRef();
    const {
        mra = {},
        mraStatus,
        mraModel,
        mraProcess,
        syncMras,
        onDetach,
        detachLoading,
        mraGroupLoading,
        dimensions,
        onStatusChange,
        loadingStatusSave,
        review,
        onReviewChange,
        loadingReviewSave,
        onOpenValidation,
    } = props;
    const { mraScores, lod2KeyDimensionsMaxScore, lod2AverageScore, versionAuthor = '', lod1Submit } = mra;

    const subdimensionsResource = new APIResource({
        id: 'mra_subdimensions',
    });
    const mras = (syncMras.length > 0 && syncMras) || [
        {
            ...mra,
            model: mraModel,
            functionalID: mraModel.functionalID, // ??? voir comment est construit le syncMras
            initialID: mraModel.initialID, // ???
            name: mraModel.name, // ???
        },
    ];

    const userRole = userIsLod2(User, mraModel) ? 'LoD2' : userIsLod1(User, mraModel) ? 'LoD1' : '';

    const showCriticalDimensions = mraProcess.systemId !== MRA_PROCESS.LOD1_LOD2 || userRole !== 'LoD1' || lod1Submit;
    const isClosedOrInCommittee = [MRA_STATUS.VALIDATED, MRA_STATUS.FINALIZED].includes(mraStatus.systemId);
    const isDeleted = mraStatus.systemId === MRA_STATUS.DELETED;
    const canDetach = !isClosedOrInCommittee && mras.length > 1 && !isDeleted;

    const parentDimension = getParamByIri(mra.dimension);
    const isNTXDimension = parentDimension.systemId && parentDimension.systemId === 'MRA_DIMENSION_SOURCE_NTX';
    const isGroupDimension = parentDimension.systemId && parentDimension.systemId === 'MRA_DIMENSION_SOURCE_GROUP';
    const radarMaxScale = isNTXDimension || isGroupDimension ? 5 : 4;

    const { t } = useTranslation();

    useEffect(() => {
        subdimensionsResource
            .apiGetCollection({
                page: 1,
                rowsPerPage: 10000,
            })
            .then((subdimensions) => setSubdimensions(subdimensions));

        return () => [];
    }, []);

    useEffect(() => {
        APIResourceStore.resources.users
            .getItemFromResourcePath(versionAuthor)
            .then((user) => setAuthor(user.fullNameWithTeam));

        return () => {};
    }, [versionAuthor]);

    useEffect(() => {
        const loadCriticalDimensions = async () => {
            const res = await Promise.all(
                mra.criticalDimensions.map((d) =>
                    APIResourceStore.resources.mra_edit_dimensions.getItemFromResourcePath(d)
                )
            );

            setCriticalDimensions(res);
        };

        loadCriticalDimensions();

        return () => {};
    }, [mra]);

    return (
        <div className="container" style={styles.container}>
            <Paper style={styles.panel}>
                <AppBar position="static" className="background-lowlight background-linear-gradient ">
                    <Toolbar>
                        <Typography variant="h5">{t('MRA Properties')}</Typography>
                    </Toolbar>
                </AppBar>
                <List style={styles.columnPanel}>
                    {mras.length > 0 && (
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                                <Avatar className="background-highlight">
                                    <SyncAltIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={t('MRA models')}
                                secondaryTypographyProps={{ component: 'div' }}
                                secondary={<>
                                    <PanelMraModelsItem
                                        mras={mras}
                                        canDetach={canDetach}
                                        detachLoading={detachLoading}
                                        onDetach={onDetach}
                                    />
                                    {mraGroupLoading && <LoadingIndicator />}
                                </>}
                            />
                        </ListItem>
                    )}

                    {review && (
                        <>
                            <Divider variant="middle" orientation="vertical" flexItem />
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar className="background-highlight">
                                        <RateReviewIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={t('Review')}
                                    secondaryTypographyProps={{ component: 'div' }}
                                    secondary={
                                        <PanelReviewItem
                                            mras={mras}
                                            review={review}
                                            onReviewChange={onReviewChange}
                                            onOpenValidation={onOpenValidation}
                                            loadingReviewSave={loadingReviewSave}
                                        />
                                    }
                                />
                            </ListItem>
                        </>
                    )}

                    <Divider variant="middle" orientation="vertical" flexItem />
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar className="background-highlight">
                                <ExploreIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={t('Status')}
                            secondaryTypographyProps={{ component: 'div' }}
                            secondary={
                                <PanelStatusItem
                                    loadingStatusSave={loadingStatusSave}
                                    onStatusChange={onStatusChange}
                                    mraStatus={mraStatus}
                                />
                            }
                        />
                    </ListItem>

                    <Divider variant="middle" orientation="vertical" flexItem />
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar className="background-highlight">
                                <PersonIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={t('Author')}
                            secondary={
                                    <span style={{ ...styles.versionAuthor, fontWeight: 'bold' }}>{author}</span>
                            }
                        />
                    </ListItem>
                </List>
            </Paper>
            <Paper style={styles.panel}>
                <AppBar position="static" className="background-lowlight background-linear-gradient ">
                    <Toolbar>
                        <Typography variant="h5">{t('MRA Conclusions')}</Typography>
                    </Toolbar>
                </AppBar>
                <List style={styles.columnPanel}>
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar className="background-highlight">
                                <NetworkCheckIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={t('Lod2 Score')}
                            secondaryTypographyProps={{ component: 'div' }}
                            secondary={
                                <PanelScoreSlidersItem
                                    averageScore={lod2AverageScore}
                                    keyDimensionsMaxScore={lod2KeyDimensionsMaxScore}
                                />
                            }
                        />
                    </ListItem>

                    <Divider variant="middle" orientation="vertical" flexItem />
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar className="background-highlight">
                                <RateReviewIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={t('Model Risk Assessment Result')}
                            secondaryTypographyProps={{ component: 'div' }}
                            secondary={
                                <PanelMraResultItem
                                    mra={mra}
                                    isGroupDimension={isGroupDimension}
                                    isNTXDimension={isNTXDimension}
                                />
                            }
                        />
                    </ListItem>

                    <Divider variant="middle" orientation="vertical" flexItem />
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar className="background-highlight">
                                <WarningRounded />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={t('Critical Dimensions')}
                            secondary={
                                <span style={styles.criticalDimensions}>
                                    {criticalDimensions && showCriticalDimensions
                                        ? criticalDimensions
                                              .map((o) => o.title)
                                              .filter((o) => o)
                                              .join(', ')
                                        : ''}

                                    {criticalDimensions.map((o) => o.title).filter((o) => o).length <
                                        criticalDimensions.length && (
                                        <LoadingIndicator styles={styles.loadingIndicator} />
                                    )}
                                </span>
                            }
                        />
                    </ListItem>

                    <Divider variant="middle" orientation="vertical" flexItem />
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar className="background-highlight">
                                <RateReviewIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={t('Chart')}
                            secondaryTypographyProps={{ component: 'div' }}
                            secondary={
                                <div>
                                    <ChartRadarDimensions
                                        radarRef={radarRef}
                                        mra={mra}
                                        dimensions={dimensions}
                                        subdimensions={subdimensions}
                                        mraScores={mraScores}
                                        radarMaxScale={radarMaxScale}
                                    />
                                </div>
                            }
                        />
                    </ListItem>
                </List>
            </Paper>
        </div>
    );
};

OverviewPanelHorizontal.propTypes = {
    mra: PropTypes.object,
    mraModel: PropTypes.object,
    /** Objet de type Parameter */
    mraProcess: PropTypes.object,
    /** Objet de type Parameter */
    mraStatus: PropTypes.object,
    syncMras: PropTypes.array,
    mraGroupLoading: PropTypes.bool,
    onDetach: PropTypes.func,
    detachLoading: PropTypes.bool,
    dimensions: PropTypes.array,
    onStatusChange: PropTypes.func,
    loadingStatusSave: PropTypes.bool,
    review: PropTypes.object,
    onReviewChange: PropTypes.func,
    loadingReviewSave: PropTypes.bool,
    onOpenValidation: PropTypes.func,
};

let styles = {
    scoreMark: {
        marginTop: 30,
        marginBottom: 0,
        height: 40,
    },
    versionAuthor: {
        marginTop: 0,
        marginBottom: 20,
        fontSize: 18,
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    criticalDimensions: {
        marginLeft: '20px',
        marginRight: '20px',
        marginTop: 0,
        marginBottom: 20,
        minHeight: 22,
        fontSize: 18,
    },
    loadingIndicator: {
        display: 'flex',
        transition: 'color 150ms',
        justifyContent: 'center',
        fontSize: '0.5rem',
        margin: '0.5rem auto 0.5rem',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    panel: {
        marginBottom: '1em',
    },
    columnPanel: {
        display: 'flex',
    },
    mragroup: {
        display: 'flex',
        placeContent: 'space-between',
        placeItems: 'center',
        marginBottom: '10px',
        background: 'rgb(89,35,127, 0.06)',
        fontSize: '13px',
        padding: '10px',
        borderRadius: '6px',
    },
    functionalid: {
        width: '70%',
        overflowWrap: 'break-word',
        textAlign: 'left',
    },
};
